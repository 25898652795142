import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ReactPlayer from "react-player"
import Header from "../components/headerBeta"
import Footer from "../components/footer"
import Carousel from "../components/carouselIndex"
import ContactBox from "../components/boxContact"
import "../styles/index.css"

export default () => (
  <div>
    <Header />
    <Carousel />
    <div className="contentbox gray">
      <Container className="text-center">
        <h1>Videos</h1>
        <hr />
      </Container>
    </div>
    <div className="arrow-up white" />
    <Container fluid>
      <Row>
        <Col sm={12} md={true} className="contentbox white full">
          <h2>Videobeschreibung</h2>
        </Col>
        <Col sm={12} md={true} className="contentbox white full">
          <ReactPlayer
            url="https://youtu.be/GN9cENdM27I"
            controls={"true"}
            fluid
          />
        </Col>
      </Row>
    </Container>
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
