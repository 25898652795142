import React from "react"
import { Navbar, Nav, Image } from "react-bootstrap"
import "../styles/navbar.css"

import imgBranding from "../assets/navbarBranding.svg"

export default () => (
  <Navbar collapseOnSelect expand="lg" className="navbar-branding">
    <Navbar.Brand href="/">
      <Image
        alt=""
        src={imgBranding}
        className="d-inline-block align-top navbar-branding-img"
      />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav>
        <Nav.Link href="/">
          <div className="nav-items">Start</div>
        </Nav.Link>
        <Nav.Link href="/beta/about">
          <div className="nav-items">Über uns</div>
        </Nav.Link>
        <Nav.Link href="/leistungen">
          <div className="nav-items">Leistungen</div>
        </Nav.Link>
        <Nav.Link href="/beta/videos">
          <div className="nav-items">Videos</div>
        </Nav.Link>
        <Nav.Link href="/dachcheck">
          <div className="nav-items">Dach-Check</div>
        </Nav.Link>
        <Nav.Link href="/kranverleih">
          <div className="nav-items">Kranverleih</div>
        </Nav.Link>
        <Nav.Link href="/kontakt">
          <div className="nav-items">Kontakt</div>
        </Nav.Link>
        <Nav.Link href="/jobs">
          <div className="nav-items last">Jobs</div>
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
)
