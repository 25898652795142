import React from "react"
import Navbar from "./navbarBeta"
import MetaData from "./metaData"
import ScrollUpButton from "react-scroll-up-button"

export default () => (
  <div className="sticky-top">
    <MetaData />
    <ScrollUpButton />
    <div className="telephone-top">&#9742; 02133 / 63294</div>
    <Navbar />
    <div style={{ "background-color": "lightgray", "padding-top": "1px" }} />
  </div>
)
